<template>
	<el-dialog width="420px" :title="invoice.id ? ('No.'+ invoice.no + ' 发票详情') : '申请发票'" :fullscreen="theme.width < 420" :visible.sync="invoice_dialog" @closed="invoice = {}" append-to-body destroy-on-close>
		<div style="position: relative;" v-if="is_show">
			<el-descriptions style="margin-top: -20px;" :labelStyle="{width: '70px'}" :column="2" :size="theme.size" border>
				<el-descriptions-item label="申请人">{{invoice.employee ? invoice.employee.name : '/'}}</el-descriptions-item>
				<el-descriptions-item label="金额"><b style="color: #ff0000;">{{invoice.amount}}</b></el-descriptions-item>
				<el-descriptions-item label="申请时间">{{invoice.created_at}}</el-descriptions-item>
				<el-descriptions-item label="类型">{{types[invoice.type]}}</el-descriptions-item>
				<el-descriptions-item label="销售单位" :span="2">{{invoice.seller.name}}</el-descriptions-item>
				<el-descriptions-item label="购买单位" :span="2">
					<p>{{invoice.name}}</p>
					<p>{{invoice.code}}</p>
					<p>{{invoice.email}}</p>
					<p>{{invoice.mobile}}</p>
					<p>{{invoice.address}}</p>
					<p>{{invoice.bank}}</p>
					<p>{{invoice.account}}</p>
				</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{invoice.remarks || '/'}}</el-descriptions-item>
				<el-descriptions-item label="发票附件" :span="2">
					<el-form ref="invoice_form" :model="invoice" :rules="invoice_rules" :size="theme.size" v-if="$utils.checkAuth($api.URI_INVOICES, 'upload')" status-icon>
						<el-form-item prop="attachments">
							<el-upload
								style="max-width: 280px;"
								:action="$api.URI_UPLOADS"
								:data="{type: contract_attach_cfg.type}"
								:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-success="(r, f, l) => { onSuccess(l); }"
								:on-preview="(f) => { openFile(f.url); }"
								:on-remove="(f, l) => { invoice = {...invoice, attachments: l}; }"
								:before-upload="beforeInvoiceAttach"
								:file-list="invoice.attachments"
								:disabled="!$utils.checkAuth($api.URI_INVOICES, 'upload')"
								:limit="1">
								<el-button type="text" icon="el-icon-upload" :disabled="(invoice.attachments && invoice.attachments.length > 0)">点击上传</el-button>
								<p slot="tip" class="el-upload__tip" style="line-height: 1.5;" v-if="$utils.checkAuth($api.URI_INVOICES, 'upload')">
									上传 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{contract_attach_cfg.format_size_unit}}
								</p>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('invoice_form')">确认上传</el-button>
						</el-form-item>
					</el-form>
					<template v-else>
						<div class="attachment" v-for="attachment in invoice.attachments" :key="attachment.id" @click="openFile(attachment.url)">
							<i class="el-icon-document"></i> {{attachment.name}}
						</div>
					</template>
				</el-descriptions-item>
			</el-descriptions>
			<div style="color: rgba(255, 0, 0, 0.2); position: absolute; top: 60px; left: 50%; font-size: 38px; font-weight: bold; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="status[invoice.status]">{{status[invoice.status]}}</div>
		</div>
		<el-form label-width="80px" ref="invoice_form" class="scroll-wrapper" style="max-height: 52vh; padding-right: 10px; overflow: auto;" :model="invoice" :rules="invoice_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="类型" prop="type">
				<el-radio-group v-model="invoice.type">
					<el-radio v-for="(type, t) in types" :key="t" :label="+t">{{type}}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="销售" prop="seller_id">
				<el-select v-model="invoice.seller_id" style="width: 100%;" placeholder="请选择销售企业" clearable filterable>
					<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="购买" prop="code" :rules="[{ required: invoice.type == 2, message: '必须填写公司纳税识别号', trigger: 'blur'}]">
				<el-input v-model="invoice.code" placeholder="请输入购买方公司纳税识别号" clearable></el-input>
			</el-form-item>
			<el-form-item prop="name">
				<el-input v-model="invoice.name" placeholder="请输入购买方公司名称" clearable></el-input>
			</el-form-item>
			<el-form-item prop="mobile" :rules="[{ required: invoice.type == 2, message: '必须填写公司联系号码', trigger: 'blur'}]">
				<el-input v-model="invoice.mobile" placeholder="请输入购买方公司联系号码" clearable></el-input>
			</el-form-item>
			<el-form-item prop="email" :rules="[{ required: invoice.type == 2, message: '必须填写公司电子邮箱', trigger: 'blur'}]">
				<el-input v-model="invoice.email" placeholder="请输入购买方公司电子邮箱" clearable></el-input>
			</el-form-item>
			<el-form-item prop="address" :rules="[{ required: invoice.type == 2, message: '必须填写购买方企业公司地址', trigger: 'blur'}]">
				<el-input v-model="invoice.address" placeholder="请输入购买方公司地址" clearable></el-input>
			</el-form-item>
			<el-form-item prop="bank" :rules="[{ required: invoice.type == 2, message: '必须填写公司开户银行', trigger: 'blur'}]">
				<el-input v-model="invoice.bank" placeholder="请输入购买方公司开户银行" clearable></el-input>
			</el-form-item>
			<el-form-item prop="account" :rules="[{ required: invoice.type == 2, message: '必须填写公司对公账户', trigger: 'blur'}]">
				<el-input v-model="invoice.account" placeholder="请输入购买方公司对公账户" clearable></el-input>
			</el-form-item>
			<el-form-item :label="models.length > 1 ? '关联' : models[invoice.common_type]" prop="common_no">
				<el-input v-model="invoice.common_no" placeholder="请输入流水号。根据财务规定是否选择填写" :readonly="invoice.common_id != null" clearable>
					<el-select slot="prepend" style="width: 100px;" v-model="invoice.common_type" v-if="models.length > 1" placeholder="关联类型" clearable filterable>
						<el-option v-for="(status, s) in models" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item label="金额" prop="amount">
				<el-input-number v-model.number="invoice.amount" placeholder="金额" :precision="2" :step="0.01" :min="0.01" :max="9909999.99"></el-input-number>
			</el-form-item>
			<el-form-item label="备注" prop="remarks">
				<el-input v-model="invoice.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('invoice_form')">{{invoice.id ? '提交保存'  : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'employee']),
			contract_attach_cfg () {
				return this.$utils.uploadConfig('invoices');
			},
			is_show () {
				const { status } = this.invoice;
				// 审核中
				if (status == 2) return true;
				// 待开票
				if (status == 4) return true;
				// 已开票
				if (status == 5) return true;
				return false;
			},
			is_upload () {
				const { status } = this.invoice;
				if (status == 4) return true;
				if (status == 5) return true;
				return false;
			}
		},
		props: {
			types: {
				type: Object
			},
			models: {
				type: Object
			},
			companies: {
				type: Array
			},
			status: {
				type: Array
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (invoice = {}) {
				if (!this.models[invoice.common_type]) return this.$message.error('未知类型');
				this.invoice = {...this.invoice, ...invoice, amount: (invoice.amount || 0)/100};
				this.invoice_dialog = true;
			},
			onSuccess (list) {
				this.invoice = {
					...this.invoice,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			beforeInvoiceAttach (file) {
				const { size, ext, format_size_unit } = this.contract_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传 '+ext.join('/').toUpperCase()+' 发票附件不能超过 '+format_size_unit);
				return is;
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					const res = await this.$http.post(this.$api.URI_INVOICES, this.invoice);
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
							this.invoice_dialog = false;
						}
					});
				});
			},
			// 提供给外部组件使用方法 - begin
			onStatus (r) {
				const { id, no, amount, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (amount/100).toFixed(2) + '</b>', '确定要提交开票申请审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\Invoice'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message.success({
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;

					default:
						this.open({...r});
					break;
				}
			},
			onRestore (r) {
				this.$confirm('No.' + r.no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (r.amount/100).toFixed(2) + '</b>', '确定要删除开票申请吗？', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_INVOICES, {params: { action: 'delete', id: r.id }, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
						}
					});
				});
			}
			// 提供给外部组件使用方法 - end
		},
		data() {
			return {
				params: {},
				invoice: {},
				invoice_dialog: false,
				invoice_rules: {
					seller_id: [{ required: true, message: '必须选择销售方', trigger: 'blur'}],
					amount: [
						{ required: true, message: '必须填写开票金额', trigger: 'blur'},
						{ type: 'number', min: 0.01, message: '开票金额必须大于0.01', trigger: ['blur', 'change']}
					],
					name: [{ required: true, message: '必须填写购买方企业名称', trigger: 'blur'}],
					type: [{ required: true, message: '必须选择发票类型', trigger: 'blur'}],
					attachments: [{ required: true, type: 'array', message: '必须上传发票附件', trigger: 'blur'}],
				}
			}
		}
	};
</script>
